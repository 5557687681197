<template>
    <Button v-if="asset === 'none'" :label="label" :class="classNode" @click="click_event" />
    <div
        v-else
        :class="`${classNode} w-24 h-24 background-scene`"
        :style="`background-image: url('${assetsSystem.getImage(asset)}');`"
    ></div>
</template>

<script>
    import assetsSystem from '@/controlers/assetControl';
    export default {
        name: 'GameButton',
        props: {
            som_click: {
                type: String,
                default: () => 'SFX_Botão_Avançar',
            },
            label: {
                type: String,
            },
            asset: {
                type: String,
                default: () => 'none',
            },
            classNode: {
                type: String,
                default: () => '',
            },
        },
        setup(props) {
            function click_event() {
                if (props.som_click) assetsSystem.play(props.som_click);

                // contx.emit('click', event);
            }

            return { click_event, assetsSystem };
        },
    };
</script>

<style lang="scss">
    .btn-game {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        span {
            font-size: 0.6rem;
            @include media-up(lg) {
                font-size: 1rem;
            }
        }
    }
</style>
