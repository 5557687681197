<template>
    <div
        class="w-12 h-12 lg:w-24 lg:h-24 background-scene"
        :style="`background-image: url('${assetsSystem.getImage(assetBtn)}');`"
        @click="clickFullScream"
    ></div>
</template>

<script>
    import { computed, onMounted, ref } from 'vue-demi';
    import assetsSystem from '@/controlers/assetControl';
    import screenControl from '@/controlers/screen';
    export default {
        name: 'ScreamButton',
        setup() {
            const isActive = ref(false);

            const assetBtn = computed(() => {
                return isActive.value ? 'dimunuir' : 'ampliar';
            });

            function clickFullScream() {
                if (document.fullscreenElement == null) {
                    screenControl.getFullscreen();
                } else {
                    document.exitFullscreen();
                }
            }

            function exitHandler() {
                if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                    isActive.value = false;
                } else {
                    isActive.value = true;
                }
            }

            onMounted(() => {
                if (document.addEventListener) {
                    document.addEventListener('fullscreenchange', exitHandler, false);
                    document.addEventListener('mozfullscreenchange', exitHandler, false);
                    document.addEventListener('MSFullscreenChange', exitHandler, false);
                    document.addEventListener('webkitfullscreenchange', exitHandler, false);
                }

                isActive.value = document.fullscreenElement == null;
            });

            return {
                isActive,
                clickFullScream,
                assetBtn,
                assetsSystem,
            };
        },
    };
</script>

<style lang="scss"></style>
