<template>
    <Teleport to="body">
        <div v-if="open" class="fixed r-0 top-0 w-full h-full z-40 flex justify-center items-center bg-t">
            <img class="w-[47%] background-fit" :src="assetsSystem.getImage(trivia.img)" alt="" />
            <div
                class="bg-white rounded-full w-12 h-12 lg:w-16 lg:h-16 mr-4 fixed top-[5%] left-[3%] flex justify-center items-center"
                @click="closeTrivia"
            >
                <i class="pi pi-times font-bold text-[#5DF8F7]" style="font-size: 2rem"></i>
            </div>

            <div class="fixed top-[10%] right-[10%]">
                <GameButton label="Detalhes" classNode="p-button-raised " @click="openDetails" />
            </div>

            <div
                v-if="openDetail"
                @click="closeDetails"
                class="fixed right-0 top-0 w-full h-full bg-black bg-opacity-90 flex justify-center items-center"
            >
                <div class="w-9/12 lg:w-7/12 text-white text-justify lg:text-xl overflow-style max-h-[80%] px-1">
                    <h1 class="font-bold text-white px-2">{{ trivia.title }}</h1>
                    <p class="my-5 px-2" v-for="(text, index) in trivia.texts" :key="index">{{ text.text }}</p>
                </div>
            </div>

            <div v-if="openDetail" class="fixed top-[10%] right-[2%] lg:right-[10%]">
                <GameButton label="Fecha" classNode="p-button-raised " @click="closeDetails" />
            </div>
        </div>
    </Teleport>
</template>

<script>
    import { onMounted, onBeforeUnmount, ref } from 'vue-demi';
    import TriviaEventtBus from '@/components/loading/LoadingEventtBus';
    import assetsSystem from '@/controlers/assetControl';

    const trivias = {
        trivia1: {
            title: 'Manifesto ao Empreendedor Brasileiro',
            texts: [
                { text: 'Você, empreendedor brasileiro, carrega o país nas costas.' },
                { text: 'Você representa 98% das empresas brasileiras e gera metade dos empregos formais no Brasil.' },
                { text: 'Você faz negócio em um país que está entre os 10 piores do mundo para se fazer negócios.' },
                { text: 'Você paga caro pela burocracia, paga caro pelo imposto, paga caro para se manter em dia.' },
                { text: 'Perde tempo. Perde dinheiro. Perde oportunidades.' },
                { text: 'Seu negócio precisa crescer, sua história precisa ser ouvida.' },
                { text: 'Afinal, o seu sucesso é o sucesso do nosso país.' },
                { text: 'Em 2013, nascemos para livrar você da burocracia e viabilizar o seu sucesso.' },
                {
                    text: 'Combinamos tecnologia e contadores fanáticos para oferecer uma contabilidade fácil, moderna e inteligente.',
                },
                {
                    text: 'Nossa missão é cuidar de toda burocracia para que você utilize o seu potencial em torno de algo produtivo e relevante.',
                },
                { text: 'Com a gente, você se torna um contador, mas um contador diferente.' },
                { text: 'O contador da sua própria história.' },
            ],
            img: 'manifesto',
        },
        trivia2: {
            title: 'Carreira em Y',
            texts: [
                {
                    text: 'A Contabilizei valoriza o crescimento profissional dos guardiões e guardiãs e entende que cada um tem um projeto de carreira próprio. Por isso, adota o modelo de carreira em Y.',
                },
                {
                    text: 'O “Y” representa uma bifurcação na jornada do profissional, onde se poderá escolher entre dois caminhos: assumir um cargo de gestão, responsável por traduzir e executar as estratégias da Contabilizei no dia a dia, ou se tornar um especialista em sua área de atuação, sem responsabilidade direta por pessoas, mas responsável por equipes de projetos.',
                },
                {
                    text: 'Para chegar lá, primeiro é importante definir uma meta baseada no que você busca para o seu futuro profissional e, depois, entender onde exatamente você se encontra hoje, analisando quais são as suas competências, valores e propósitos.',
                },
                {
                    text: 'Com essas definições bem claras, procure por oportunidades dentro da Contabilizei que te ajudarão a atingir a sua meta, compreendendo quais são os obstáculos que enfrentará e os compromissos que está disposto a assumir.',
                },
                {
                    text: 'Conte com a Contabilizei para realizar os seus sonhos, pois o seu sucesso profissional é o nosso sucesso.',
                },
            ],
            img: 'prancheta',
        },
    };

    export default {
        setup() {
            const open = ref(false);
            const openDetail = ref(false);
            const trivia = ref({});

            function openTrivia(type) {
                trivia.value = trivias[type];
                open.value = true;
            }

            function closeTrivia() {
                open.value = false;
            }

            function openDetails() {
                openDetail.value = true;
            }

            function closeDetails() {
                openDetail.value = false;
            }

            onMounted(() => {
                console.log('dd');
                TriviaEventtBus.on('openTrivia', openTrivia);
                TriviaEventtBus.on('closeTrivia', closeTrivia);
            });

            onBeforeUnmount(() => {
                TriviaEventtBus.off('openTrivia', openTrivia);
                TriviaEventtBus.off('closeTrivia', closeTrivia);
            });

            return { open, trivia, assetsSystem, closeTrivia, openDetail, openDetails, closeDetails };
        },
    };
</script>

<style lang="scss">
    .bg-t {
        background: rgb(253, 249, 238);
        background: radial-gradient(circle, rgba(253, 249, 238, 1) 70%, rgba(252, 228, 200, 1) 100%);
    }
    .background-fit {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
</style>
