import { createRouter, createWebHistory } from 'vue-router';
import aplication from '@/views/Aplication.vue';
import auth from '@/plugins/authentication';

const start = () => import(/* webpackChunkName: "login" */ '@/views/login');
const login = () => import(/* webpackChunkName: "login" */ '@/views/login/login');
const installApp = () => import(/* webpackChunkName: "login" */ '@/views/login/installApp');

const Home = () => import(/* webpackChunkName: "home" */ '@/views/home/home.vue');
const NotFound = () => import(/* webpackChunkName: "notFound" */ '@/views/404.vue');

const RegionsMap = () => import(/* webpackChunkName: "RegionsMap" */ '@/views/regions/RegionsMap.vue');
const RegionsLoading = () => import(/* webpackChunkName: "RegionsMap" */ '@/views/regions/RegionsLoading.vue');
const RegionsCase = () => import(/* webpackChunkName: "RegionsMap" */ '@/views/regions/RegionsCase.vue');

const Chat = () => import(/* webpackChunkName: "Chat" */ '@/views/chat/ChatView.vue');

const Browser = () => import(/* webpackChunkName: "BrowserCTBZ" */ '@/views/browser/BrowserCTBZ.vue');

const EndGame = () => import(/* webpackChunkName: "EndGame" */ '@/views/EndGame');

const routes = [
    {
        path: '/:pathMatch(.*)*',
        redirect: '/not/found',
    },
    {
        path: '/not/found',
        name: 'NotFound',
        component: NotFound,
        meta: {
            module: '',
            mode: '',
            showNav: false,
        },
    },
    {
        path: '/app',
        name: 'aplication',
        component: aplication,
        meta: {
            login: true,
        },
        children: [
            {
                path: '/home',
                name: 'Home',
                component: Home,
                meta: {
                    mode: 'all',
                },
            },
            {
                path: '/regions',
                name: 'RegionsMap',
                component: RegionsMap,
                meta: {
                    mode: 'all',
                },
            },
            {
                path: '/loading',
                name: 'RegionsLoading',
                component: RegionsLoading,
                // beforeEnter: verifyCaseID,
                meta: {
                    mode: 'all',
                },
            },
            {
                path: '/regions/case',
                name: 'RegionsCase',
                component: RegionsCase,
                // beforeEnter: verifyCaseID,
                meta: {
                    mode: 'all',
                },
            },
            {
                path: '/chat/:chatID',
                name: 'Chat',
                component: Chat,
                // beforeEnter: verifyCaseID,
                meta: {
                    mode: 'all',
                },
            },
            {
                path: '/browser/:type',
                name: 'Browser',
                component: Browser,
                // beforeEnter: verifyCaseID,
                meta: {
                    mode: 'all',
                },
            },
            {
                path: '/endgame',
                name: 'EndGame',
                component: EndGame,
                // beforeEnter: verifyCaseID,
                meta: {
                    mode: 'all',
                },
            },
        ],
    },
    {
        path: '/',
        name: 'start',
        component: start,
        beforeEnter: clientIsLoggedIn,
    },
    {
        path: '/:token',
        name: 'startToken',
        component: start,
        beforeEnter: clientIsLoggedIn,
    },
    {
        path: '/login',
        name: 'login',
        component: login,
        beforeEnter: clientIsLoggedIn,
    },
    {
        path: '/installapp',
        name: 'installApp',
        component: installApp,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { top: 0 };
    },
});

// function verifyCaseID(to) {
//     console.log(to)
//     if (to.params.caseID === undefined) {
//         router.push({ name: 'RegionsMap' });
//         log('[verifyCaseID] Erro para verificar a rota.', '#FF0000');
//         log(to.params.caseID, '#FF0000');
//     }
// }

function clientIsLoggedIn() {
    console.log('auth', auth.logged);
    if (auth.logged) {
        router.push({ name: 'Home' });
    }
}

router.beforeEach((to) => {
    if (to.matched.some((record) => record.meta.login)) {
        // verifica se a rota precisa ser autenticada
        if (auth.isAuthenticated()) {
            //verifica se o usuario tem acesso
            if (to.meta.mode === 'all' || auth.accessLevel == to.meta.mode) {
                return true;
            } else {
                //verifica se o caminho e o 404 e se for deixa passar
                if (to.path == '/not/found') return true;
                else return '/not/found';
            }
        } else {
            console.log('usuário não logado, rota protegida');
            auth.logout();

            return { name: 'start', query: { to: to.path } };
        }
    } else {
        return true;
    }
});



export default router;
