<template>
    <div class="h-full">
        <!-- <div
            v-if="!openProtect"
            class="w-full h-full flex justify-center items-center fixed top-0 right-0 bg-white z-50"
        >
            <div class="flex justify-center items-center flex-col">
                <p class="text-center mb-2">Para iniciar clique no botão "Iniciar" ou vire o celular em modo paisagem</p>
                <button @click="getFullscreen" class="p-4 bg-primary rounded-card text-white">Iniciar</button>
            </div>
        </div> -->

        <router-view v-if="canRender" />
    </div>

    <ConfirmDialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }"></ConfirmDialog>
    <Toast />
    <ModalLoading />
    <TutorialV />
    <Trivias />
    <span class="fixed text-white text-20 right-3 bottom-1">{{ version }}</span>
</template>

<script>
    import splashScreen from '@/plugins/splashScreen';
    import authentication from '@/plugins/authentication';
    import screenControl from '@/controlers/screen';
    import assetsSystem from '@/controlers/assetControl';
    import TutorialV from '@/components/tour/Tutorial.vue';
    import Trivias from '@/components/trivias/MainTrivias.vue';
    import posthog from 'posthog-js';
    import { onMounted, ref } from 'vue';
    import { useRouter } from 'vue-router';

    export default {
        components: { TutorialV, Trivias },
        setup() {
            const canRender = ref(false);
            const openProtect = ref(true);
            const version = ref(window.version);

            window.router = useRouter();

            authentication.load();

            assetsSystem
                .loading()
                .then(() => {
                    splashScreen.off();
                    canRender.value = true;
                })
                .catch((error) => {
                    console.log(error);
                });

            function getFullscreen() {
                screenControl.getFullscreen();
            }

            window.addEventListener('islandscape', (e) => {
                openProtect.value = e.detail.islandscape;
                console.log('islandscape:', e.detail.islandscape);
            });

            window.addEventListener('AssetsSystemDownload', (e) => {
                splashScreen.mensagem('Carregando arquivos de ' + e.detail.type + ' - ' + e.detail.info);
            });

            onMounted(() => {
                posthog.init('phc_7ahvZGoz5fOJ9y0Kq5txgvqA5pGpKdOIXclGSDjbAjM', {
                    api_host: 'https://app.posthog.com',
                    autocapture: false,
                });
                console.log('posthog-init');
                console.log('cookies', document.cookie);
            });

            return { canRender, openProtect, getFullscreen, version };
        },
    };
</script>

<style lang="scss"></style>
