<template>
    <div class="h-full w-full">
        <MobileWarn class="display-protect z-[500] fixed w-full h-full top-0 left-0" />
        <router-view id="viewBase" class="" />
    </div>
</template>

<script setup>
    import screenControl from '@/controlers/screen';
    import MobileWarn from '../components/MobileWarn.vue';
    import { onMounted } from 'vue';
    import authentication from '@/plugins/authentication';

    import posthog from 'posthog-js';

    screenControl.active();

    onMounted(() => {
        let user_email = authentication.getData().email;

        posthog.identify(user_email);
        console.log('posthog-identify', user_email);
    });
</script>

<style lang="css">
    .display-protect {
        display: none;
    }
    @media only screen and (orientation: portrait) {
        .display-protect {
            display: flex;
        }
    }
</style>
