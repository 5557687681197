import log from './logger';

function getAspectRatio(width, height) {
    var ratio = width / height;
    return Math.abs(ratio - 4 / 3) < Math.abs(ratio - 16 / 9) ? '4:3' : '16:9';
}

function makeLandscape(AspectRatio) {
    // this works on android, not iOS
    if (screen.orientation && screen.orientation.lock) {
        if (AspectRatio == '4:3') {
            screen.orientation.lock('landscape');
        }
    }
}

class screenF {
    constructor() {
        this.status = {
            aspectRatio: '',
            islandscape: true,
            isActive: false,
        };
        this.resizeVerification();
    }
    resizeVerification() {
        this.status.aspectRatio = getAspectRatio(screen.width, screen.height);
        log(`w:${window.innerWidth} h:${window.innerHeight}`, '#14b21f')

        if (!this.status.isActive) return;

        if (this.status.aspectRatio == '16:9') {
            if (!this.status.islandscape)
                window.dispatchEvent(new CustomEvent('islandscape', { detail: { islandscape: true } }));
            this.status.islandscape = true;
        } else {
            if (this.status.islandscape)
                window.dispatchEvent(new CustomEvent('islandscape', { detail: { islandscape: false } }));
            this.status.islandscape = false;
        }
    }
    active() {
        if (screen.orientation === undefined) {
            log('[screen] Sistema de Free screen não existe desabilitando proteção', '#de0505');
            this.status.isActive = false;
            return;
        }
        this.status.isActive = true;
        this.resizeVerification();
        window.addEventListener('resize', () => this.resizeVerification());
    }
    disable() {
        this.status.isActive = false;
        window.removeEventListener('resize', () => this.resizeVerification());
    }
    getFullscreen() {
        var element = document.querySelector('body');
        if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
            if (this.status.aspectRatio == '') makeLandscape(getAspectRatio(screen.width, screen.height));
            else makeLandscape(this.status.aspectRatio);
        } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen();
            if (this.status.aspectRatio == '') makeLandscape(getAspectRatio(screen.width, screen.height));
            else makeLandscape(this.status.aspectRatio);
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
            if (this.status.aspectRatio == '') makeLandscape(getAspectRatio(screen.width, screen.height));
            else makeLandscape(this.status.aspectRatio);
        } else {
            console.log('ee');
        }
    }
}

const screenControl = new screenF();

export default screenControl;
