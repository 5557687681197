import { reactive } from 'vue';
import VTourr from './VTour.vue';
import VStep from './VStep.vue';

class VTourC {
    constructor() {
        this.instance = reactive({});
    }
    setInstance(name, obj) {
        this.instance[name] = obj;
    }
    getInstance(name) {
        return this.instance[name];
    }
}

export const Vtour = new VTourC();

export const VTour = (app) => {
    app.config.globalProperties.$tours = {};

    app.component(VTourr.name, VTourr);
    app.component(VStep.name, VStep);
};
