<template>
    <div class="flex-col justify-center items-center h-full p-10 bg-[#FFF9EF]">
        <div class="p-5 bg-primary rounded-card anime">
            <img class="object-contain w-24" :src="mobileRequire" alt="" />
        </div>
        <div class="anime justify-center mt-[4rem]">
            <h2 class="font-bold text-[2rem] text-primary mt-10 text-center">ATENÇÃO!</h2>
            <p class="mt-5 text-18 text-center max-w-sm">
                A partir de agora, jogue com o celular na <b>horizontal.</b><br /><br />
                Lembre-se de <b>ativar a rotação de tela</b> nas configurações do seu dispositivo.
            </p>
        </div>
        <div class="h-[7rem]"></div>
        <GameButton
            label="Preciso de Ajuda"
            classNode="text-20 lg:text-4xl py-2 lg:py-4 px-10 lg:px-32 rounded-full mt-5 bg-primary text-white fixed bottom-10"
            @click="enterInstructions"
        />

        <div
            v-if="showInstructions"
            class="fixed bg-[#FFF9EF] top-0 right-0 w-screen h-screen flex flex-col justify-center items-center px-3"
        >
            <template v-if="step == 1 && typeos == 'iphone'">
                <div class="absolute bottom-0 flex flex-col justify-cente items-center px-5 w-screen">
                    <h2 class="font-bold text-[2rem] text-center text-primary">1</h2>
                    <p class="mt-5 text-18 text-center max-w-sm">
                        Deslize a tela de cima para baixo para acessar a <b>Central de Controle.</b>
                    </p>
                    <p class="mt-5 text-14 text-center max-w-sm mb-10">
                        (Você precisará deslizar de baixo para cima em modelos mais antigos)
                    </p>

                    <img class="object-contain w-[80%]" :src="assetsSystem.getImage('COMP_iosbarra')" alt="" />
                </div>
                <div class="flex justify-center gap-3 fixed bottom-0 w-full bg-opacity pb-10">
                    <GameButton
                        label="Próximo"
                        classNode="text-20 lg:text-4xl py-2 lg:py-4 px-10 lg:px-32 rounded-full mt-5 bg-primary text-white"
                        @click="changeStep(2)"
                    />
                </div>
            </template>
            <template v-if="step == 2 && typeos == 'iphone'">
                <div class="absolute bottom-0 flex flex-col justify-cente items-center px-5 w-screen">
                    <h2 class="font-bold text-[2rem] text-center text-primary">2</h2>
                    <p class="mt-5 text-18 text-center max-w-sm mb-10">
                        Clique no <b>ícone do cadeado</b> no menu. Ele ficará branco quando a rotação estiver
                        habilitada.
                    </p>

                    <img class="object-contain w-[80%]" :src="assetsSystem.getImage('COMP_iosativar')" alt="" />
                </div>
                <div class="flex justify-center gap-3 fixed bottom-0 w-full bg-opacity pb-10">
                    <GameButton
                        label="Anterior"
                        classNode="text-20 lg:text-4xl py-2 lg:py-4 px-10 lg:px-32 rounded-full mt-5 bg-primary text-white"
                        @click="changeStep(1)"
                    />
                    <GameButton
                        label="Finalizar"
                        classNode="text-20 lg:text-4xl py-2 lg:py-4 px-10 lg:px-32 rounded-full mt-5 bg-primary text-white"
                        @click="closeInstructions()"
                    />
                </div>
            </template>

            <template v-if="step == 1 && typeos == 'android'">
                <div class="absolute bottom-0 flex flex-col justify-cente items-center px-5 w-screen">
                    <h2 class="font-bold text-[2rem] text-center text-primary">1</h2>
                    <p class="mt-5 text-18 text-center mb-10">
                        Abra o <b>painel de configurações rápidas</b> deslizando o dedo de cima para baixo na tela.
                    </p>

                    <img class="object-contain w-[80%]" :src="assetsSystem.getImage('COMP_androidbarra')" alt="" />
                </div>

                <div class="flex justify-center gap-3 fixed bottom-0 w-full bg-opacity pb-10">
                    <GameButton
                        label="Próximo"
                        classNode="text-20 lg:text-4xl py-2 lg:py-4 px-10 lg:px-32 rounded-full mt-5 bg-primary text-white"
                        @click="changeStep(2)"
                    />
                </div>
            </template>
            <template v-if="step == 2 && typeos == 'android'">
                <div class="absolute bottom-0 flex flex-col justify-cente items-center px-5 w-screen">
                    <h2 class="font-bold text-[2rem] text-center text-primary">2</h2>
                    <p class="mt-5 text-18 text-center mb-10">
                        Ative a <b>Rotação Automática</b>. Se o ícone já disser Rotação Automática, significa que ela já
                        está ativada.
                    </p>

                    <img class="object-contain w-[80%]" :src="assetsSystem.getImage('COMP_androidativar')" alt="" />
                </div>

                <div class="flex justify-center gap-3 fixed bottom-0 w-full bg-opacity pb-10">
                    <GameButton
                        label="Anterior"
                        classNode="text-20 lg:text-4xl py-2 lg:py-4 px-10 lg:px-32 rounded-full mt-5 bg-primary text-white"
                        @click="changeStep(1)"
                    />
                    <GameButton
                        label="Finalizar"
                        classNode="text-20 lg:text-4xl py-2 lg:py-4 px-10 lg:px-32 rounded-full mt-5 bg-primary text-white"
                        @click="closeInstructions()"
                    />
                </div>
            </template>
        </div>
    </div>
</template>
<script setup>
    import assetsSystem from '@/controlers/assetControl';
    import mobileRequire from '@/assets/img/components/mobile-rotate.png';
    import { ref, onMounted } from 'vue';
    import { typeOS } from '@/controlers/osDetector';
    import posthog from 'posthog-js';

    const showInstructions = ref(false);
    const step = ref(1);
    const typeos = ref('');

    function enterInstructions() {
        typeos.value = typeOS();
        showInstructions.value = true;
        posthog.capture('click_mobile_instructions_rotating');
    }
    function changeStep(newStep) {
        step.value = newStep;
    }
    function closeInstructions() {
        showInstructions.value = false;
        step.value = 1;
    }

    onMounted(() => {
        posthog.capture('mobile_warn_screen');
    });
</script>
<style lang="scss">
    .anime {
        animation: girarInfinitamente 8s cubic-bezier(0.75, 0.17, 0.52, 1.04) infinite;
    }

    @keyframes girarInfinitamente {
        0% {
            transform: rotate(0deg);
        }
        54.5%,
        100% {
            transform: rotate(90deg);
        }
    }
</style>
