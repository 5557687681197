import { reactive } from 'vue';
import { Vtour } from '@/components/tour/TourService';
import log from './logger';

const storyBoard = {
    home: {
        hub_intro: [
            {
                target: '#tour-hub-1',
                content: 'Olá, bem-vindo e bem-vinda ao Game C.onecta!',
            },
            {
                target: '#tour-hub-1',
                content:
                    'A partir de agora e durante os próximos 45 minutos, você assumirá o papel de um Guardião ou Guardiã da Contabilizei em uma missão extraordinária!',
            },
            {
                target: '#tour-hub-1',
                content:
                    'Você visitará e conhecerá a realidade de três pessoas empreendedoras, que terão suas vidas impactadas pela Contabilizei.',
                params: {
                    on_next: async () => {
                        window.dispatchEvent(new CustomEvent('opneCall'));
                    },
                },
            },
            {
                target: '#tour-hub-1',
                content:
                    'Está ouvindo isso!? Você está recebendo uma chamada de vídeo da Laura, a sua liderança direta. É melhor não deixá-la esperando. Boa sorte!',
            },
        ],
        hub_intro_dois: [
            {
                target: '#tour-hub-1',
                content:
                    'Agora que a missão foi oficialmente dada, vou passar algumas instruções de jogo. Este é o seu escritório e aqui você pode fazer alguns acessos relevantes.',
            },
            {
                target: '#tour-hub-3',
                content:
                    'Como você já sabe, esta tela é para fazer as chamadas de vídeo com a sua liderança. Quando você cumprir todos os seus objetivos, você receberá outra ligação.',
                params: {
                    placement: 'top',
                },
            },
            {
                target: '#objetive',
                content:
                    'Você acompanha as suas tarefas neste quadro e nesta barra de progresso. Se não souber o que fazer em seguida, não se esqueça de conferir estes indicadores.',
            },
            {
                target: '#tour-hub-2',
                content:
                    'Este é o seu tablet. Você foi selecionado(a) para testar um novo e revolucionário app de mensagens, o Conexão Futuro, que ainda está em fase beta de teste.',
            },
            {
                target: '#tour-hub-2',
                content: 'Fique de olho para ler as mensagens recebidas durante a jornada.',
            },
            {
                target: '#tour-hub-1',
                content:
                    'Por último, clique aqui no monitor, para visitar clientes pelo Brasil. Boa viagem!',
                params: {
                    placement: 'top',
                },
            },
        ],
    },
    cases: {
        case: [
            {
                target: '#tutorial-case',
                content:
                    'Espero que você tenha feito uma boa viagem! Durante a sua visita, você vai lidar com diversas situações da realidade profissional do cliente.',
            },
            {
                target: '#tutorial-case',
                content:
                    'Leia muito atentamente os diálogos e quando o/a cliente pedir a sua opinião, você terá 120 segundos para escolher uma entre três alternativas. Boa partida!',
                params: {
                    placement: 'top',
                },
            },
        ],
    },
};

function saveInLocalStorage(data) {
    window.localStorage.setItem('tu', JSON.stringify(data));
}

class tutorialCore {
    constructor() {
        this.data = reactive({
            trilhas: {
                home: {
                    isCompleted: false,
                },
                cases: {
                    isCompleted: false,
                },
            },
            nextTutorial: 'hub_intro',
            currentTrilha: '',
            hub_intro: false,
        });

        var storage = window.localStorage.getItem('tu');

        if (storage != undefined) {
            this.data = JSON.parse(storage);
        }
    }
    start(trilha) {
        log(`Iniciando tutorial trilha "${trilha}"`, '#6164c6');
        if (trilha != 'home' && !this.data.trilhas.home.isCompleted) {
            log(`Primeira trilha ainda não foi completa`, '#6164c6');
            return;
        }
        if (this.data.trilhas[trilha].isCompleted) {
            log(`Trilha "${trilha}" já está completa`, '#6164c6');
            return;
        }
        this.data.currentTrilha = trilha;
        var storyBoardToActive = this.data.nextTutorial;

        const tour = Vtour.getInstance(storyBoardToActive);

        log(`Iniciando storyBoard "${this.data.nextTutorial}"`, '#6164c6');
        tour.start();
    }
    completed() {
        Object.keys(storyBoard).forEach((trilhasKey, trilhasIndex) => {
            if (this.data.trilhas[trilhasKey].isCompleted) return;
            if (this.data.currentTrilha !== trilhasKey) return;

            log(`Completando storyBoard ${this.data.nextTutorial}`, '#6164c6');

            const storyBoards = storyBoard[trilhasKey];
            const storyBoardsKeys = Object.keys(storyBoards);

            const currentStoryBoardindex = storyBoardsKeys.findIndex((i) => i == this.data.nextTutorial);

            if (currentStoryBoardindex + 1 === storyBoardsKeys.length) {
                log(`Trilha "${trilhasKey}" completa`, '#6164c6');
                this.data.trilhas[trilhasKey].isCompleted = true;

                const trilhasName = Object.keys(this.data.trilhas);

                const trilhaName = trilhasName[trilhasIndex + 1];

                if (trilhaName === undefined) {
                    log(`Todas a trilhas foram completas`, '#6164c6');
                    saveInLocalStorage(this.data);
                    return;
                }

                this.data.nextTutorial = Object.keys(storyBoard[trilhaName])[0];
                console.log('this.data.nextTutorial', this.data.nextTutorial);

                saveInLocalStorage(this.data);
                return;
            }

            this.data.nextTutorial = storyBoardsKeys[currentStoryBoardindex + 1];
        });

        saveInLocalStorage(this.data);
    }
    storyBoards() {
        const storyBoards = {};

        for (const key in storyBoard) {
            const trilhas = storyBoard[key];

            for (const trilhasKeys in trilhas) {
                storyBoards[trilhasKeys] = trilhas[trilhasKeys];
            }
        }
        return storyBoards;
    }
}

const tutorial = new tutorialCore();

export default tutorial;
