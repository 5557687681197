/* eslint-disable no-unused-vars */
import { Howl, Howler } from 'howler';
// import axios from 'axios';
import posthog from 'posthog-js';

const assets = {
    essential: {
        images: {
            BG_inicio: 'https://cdn.eludico.io/conecta/BG_inicio.png',
            BG_tablet: 'https://cdn.eludico.io/conecta/BG_tablet.png',
            BG_hub: 'https://cdn.eludico.io/conecta/BG_hub.jpeg',
            som_off: 'https://cdn.eludico.io/conecta/som_off.png',
            som_on: 'https://cdn.eludico.io/conecta/som_on.png',
            caneca: 'https://cdn.eludico.io/conecta/caneca.png',
            manifesto: 'https://cdn.eludico.io/conecta/manifesto.png',
            prancheta: 'https://cdn.eludico.io/conecta/prancheta.png',
            hub_notification: 'https://cdn.eludico.io/conecta/hub_notification.png',
            hover_mapa_brasil: 'https://cdn.eludico.io/conecta/hover_mapa_brasil.png',
            hover_celular: 'https://cdn.eludico.io/conecta/hover_celular.png',
            hover_contabilizei: 'https://cdn.eludico.io/conecta/hover_contabilizei.png',
            hover_manifesto: 'https://cdn.eludico.io/conecta/hover_manifesto.png',
            hover_lo: 'https://cdn.eludico.io/conecta/hover_lo.png',
            call: 'https://cdn.eludico.io/conecta/call.png',
            botao_hub: 'https://cdn.eludico.io/conecta/botao_hub.png',
            mapa_brasil: 'https://cdn.eludico.io/conecta/mapa_brasil.png',
            parana_idle: 'https://cdn.eludico.io/conecta/parana_idle.png',
            sp_idle: 'https://cdn.eludico.io/conecta/sp_idle.png',
            ceara_idle: 'https://cdn.eludico.io/conecta/ceara_idle.png',
            parana_select: 'https://cdn.eludico.io/conecta/parana_select.png',
            sp_select: 'https://cdn.eludico.io/conecta/sp_select.png',
            ceara_select: 'https://cdn.eludico.io/conecta/ceara_select.png',
            parana_complete: 'https://cdn.eludico.io/conecta/parana_complete.png',
            sp_complete: 'https://cdn.eludico.io/conecta/sp_complete.png',
            ceara_complete: 'https://cdn.eludico.io/conecta/ceara_complete.png',
            brasil_sombra: 'https://cdn.eludico.io/conecta/brasil_sombra.png',
            BG_sp_map: 'https://cdn.eludico.io/conecta/BG_sp_map.png',
            BG_parana_map: 'https://cdn.eludico.io/conecta/BG_parana_map.png',
            BG_ceara_map: 'https://cdn.eludico.io/conecta/BG_ceara_map.png',
            BG_loading_sp: 'https://cdn.eludico.io/conecta/BG_loading_sp.png',
            BG_loading_ceara: 'https://cdn.eludico.io/conecta/BG_loading_ceara.png',
            BG_loading_parana: 'https://cdn.eludico.io/conecta/BG_loading_parana.png',
            icon_futurapp: 'https://cdn.eludico.io/conecta/icon_futurapp.png',
            icon_parana: 'https://cdn.eludico.io/conecta/icon_parana.png',
            icon_sp: 'https://cdn.eludico.io/conecta/icon_sp.png',
            icon_ceara: 'https://cdn.eludico.io/conecta/icon_ceara.png',
            call_hud: 'https://cdn.eludico.io/conecta/call_hud.png',
            call_hud_active: 'https://cdn.eludico.io/conecta/call_hud_active.png',
            lider_call_start: 'https://cdn.eludico.io/conecta/lider_call_start.jpeg',
            lider_call_end: 'https://cdn.eludico.io/conecta/lider_call_end.png',
            Cases_notebook: 'https://cdn.eludico.io/conecta/Cases_notebook.png',
            loading_parana: 'https://cdn.eludico.io/conecta/loading_parana.png',
            loading_ceara: 'https://cdn.eludico.io/conecta/loading_ceara.png',
            loading_sp: 'https://cdn.eludico.io/conecta/loading_sp.png',
            ctbz_trivia_img2: 'https://cdn.eludico.io/conecta/ctbz_trivia_img2.png',
            ctbz_trivia_img: 'https://cdn.eludico.io/conecta/ctbz_trivia_img.png',
            botao_tempo: 'https://cdn.eludico.io/conecta/botao_tempo.png',
            dimunuir: 'https://cdn.eludico.io/conecta/dimunuir.png',
            ampliar: 'https://cdn.eludico.io/conecta/ampliar.png',
            exit_button: 'https://cdn.eludico.io/conecta/exit_button.png',
            COMP_bestxp: 'https://cdn.eludico.io/conecta/COMP_bestxp.png',
            COMP_androidativar: 'https://cdn.eludico.io/conecta/COMP_androidativar.png',
            COMP_androidbarra: 'https://cdn.eludico.io/conecta/COMP_androidbarra.png',
            COMP_chromeadd: 'https://cdn.eludico.io/conecta/COMP_chromeadd.png',
            COMP_chromeopts: 'https://cdn.eludico.io/conecta/COMP_chromeopts.png',
            COMP_home: 'https://cdn.eludico.io/conecta/COMP_home.png',
            COMP_iosativar: 'https://cdn.eludico.io/conecta/COMP_iosativar.png',
            COMP_iosbarra: 'https://cdn.eludico.io/conecta/COMP_iosbarra.png',
            COMP_safariadd: 'https://cdn.eludico.io/conecta/COMP_safariadd.png',
            COMP_safariconcluir: 'https://cdn.eludico.io/conecta/COMP_safariconcluir.png',
            COMP_safariopts: 'https://cdn.eludico.io/conecta/COMP_safariopts.png',
            COMP_editacoes: 'https://cdn.eludico.io/conecta/COMP_editacoes.png',
            COMP_gupy: 'https://cdn.eludico.io/conecta/COMP_gupy.png',
        },
        audios: {
            bg: {
                bgs_abertura: 'https://cdn.eludico.io/conecta/bgs_abertura.ogg',
                bgs_escritorio: 'https://cdn.eludico.io/conecta/bgs_escritorio.ogg',
                // BGS_parana: 'https://cdn.eludico.io/conecta/BGS_parana.ogg',
                // BGS_ceara: 'https://cdn.eludico.io/conecta/BGS_ceara.ogg',
                // BGS_sp: 'https://cdn.eludico.io/conecta/BGS_sp.ogg',

                bgs_fortaleza1: 'https://cdn.eludico.io/conecta/bgs_fortaleza1.ogg',
                bgs_curitiba1: 'https://cdn.eludico.io/conecta/bgs_curitiba1.ogg',
                bgs_saopaulo1: 'https://cdn.eludico.io/conecta/bgs_saopaulo1.ogg',
                bgs_encerramento: 'https://cdn.eludico.io/conecta/bgs_encerramento.ogg',
            },
            jng: {},
            sfx: {
                SFX_Abas_Tablet: 'https://cdn.eludico.io/conecta/SFX_Abas_Tablet.mp3',
                SFX_Botão_Avançar: 'https://cdn.eludico.io/conecta/SFX_Botao_Avancar.mp3',
                sfx_notificacao: 'https://cdn.eludico.io/conecta/sfx_notificacao.ogg',
                SFX_Swoosh_Carrosel: 'https://cdn.eludico.io/conecta/SFX_Swoosh_Carrosel.mp3',
                sfx_relogio: 'https://cdn.eludico.io/conecta/sfx_relogio.ogg',
                sfx_chamadadevideo: 'https://cdn.eludico.io/conecta/sfx_chamadadevideo.ogg',

                amb_escritorio: 'https://cdn.eludico.io/conecta/amb_escritorio.ogg',
                amb_escritorio1: 'https://cdn.eludico.io/conecta/amb_escritorio1.ogg',

                sfx_aviao: 'https://cdn.eludico.io/conecta/sfx_aviao.ogg',
                sfx_aviao_loading: 'https://cdn.eludico.io/conecta/sfx_aviao_loading.ogg',
                sfx_campainha: 'https://cdn.eludico.io/conecta/sfx_campainha.ogg',
                sfx_notebook: 'https://cdn.eludico.io/conecta/sfx_notebook.ogg',
            },
            voz: {},
        },
    },
    sp: {
        images: {
            CaseMariana_masp: 'https://cdn.eludico.io/conecta/CaseMariana_masp.png',
            CaseMariana_ponteestaiada: 'https://cdn.eludico.io/conecta/CaseMariana_ponteestaiada.png',
            CaseMariana_foodtruck1: 'https://cdn.eludico.io/conecta/CaseMariana_foodtruck1.png',
            CaseMariana_foodtruck2: 'https://cdn.eludico.io/conecta/CaseMariana_foodtruck2.png',
            CaseMariana_loja: 'https://cdn.eludico.io/conecta/CaseMariana_loja.png',
            CaseMariana_loja2: 'https://cdn.eludico.io/conecta/CaseMariana_loja2.png',

            Mariana_brava: 'https://cdn.eludico.io/conecta/Mariana_brava.png',
            Mariana_celebrativa: 'https://cdn.eludico.io/conecta/Mariana_celebrativa.png',
            Mariana_infeliz: 'https://cdn.eludico.io/conecta/Mariana_infeliz.png',
            Mariana_neutra: 'https://cdn.eludico.io/conecta/Mariana_neutra.png',
            Mariana_pensativa: 'https://cdn.eludico.io/conecta/Mariana_pensativa.png',
            Mariana_pensativa2: 'https://cdn.eludico.io/conecta/Mariana_pensativa2.png',
            Renata: 'https://cdn.eludico.io/conecta/Renata.png',
        },
        audios: {
            bg: {
                bgs_saopaulo2: 'https://cdn.eludico.io/conecta/bgs_saopaulo2.ogg',
            },
            jng: {},
            sfx: {
                amb_trafegodecarros: 'https://cdn.eludico.io/conecta/amb_trafegodecarros.ogg',
                sfx_sinodeloja: 'https://cdn.eludico.io/conecta/sfx_sinodeloja.ogg',
                amb_foodtrucks: 'https://cdn.eludico.io/conecta/amb_foodtrucks.ogg',
            },
            voz: {},
        },
    },
    ceara: {
        images: {
            CaseDouglas_bar: 'https://cdn.eludico.io/conecta/CaseDouglas_bar.png',
            CaseDouglas_encerramento: 'https://cdn.eludico.io/conecta/CaseDouglas_encerramento.png',
            CaseDouglas_escritorio1: 'https://cdn.eludico.io/conecta/CaseDouglas_escritorio.png',
            CaseDouglas_escritorio2: 'https://cdn.eludico.io/conecta/CaseDouglas_escritorio2.png',
            CaseDouglas_escritorio3: 'https://cdn.eludico.io/conecta/CaseDouglas_escritorio3.png',
            CaseDouglas_pebolim: 'https://cdn.eludico.io/conecta/CaseDouglas_pebolim.png',
            CaseDouglas_salareuniao: 'https://cdn.eludico.io/conecta/CaseDouglas_salareuniao.png',

            Douglas_maosunidas: 'https://cdn.eludico.io/conecta/Douglas_maosunidas.png',
            Douglas_neutro: 'https://cdn.eludico.io/conecta/Douglas_neutro.png',
            Douglas_pensativo: 'https://cdn.eludico.io/conecta/Douglas_pensativo.png',
            Douglas_pensativo2: 'https://cdn.eludico.io/conecta/Douglas_pensativo2.png',
            Douglas_segurandocelular: 'https://cdn.eludico.io/conecta/Douglas_segurandocelular.png',
            Douglas_feliz: 'https://cdn.eludico.io/conecta/Douglas_feliz.png',
            Douglas_incredulo: 'https://cdn.eludico.io/conecta/Douglas_incredulo.png',
        },
        audios: {
            bg: {
                bgs_fortaleza2: 'https://cdn.eludico.io/conecta/bgs_fortaleza2.ogg',
            },
            jng: {},
            sfx: {
                amb_beiramar: 'https://cdn.eludico.io/conecta/amb_beiramar.ogg',

                sfx_pebolim: 'https://cdn.eludico.io/conecta/sfx_pebolim.ogg',
                sfx_passos: 'https://cdn.eludico.io/conecta/sfx_passos.ogg',
            },
            voz: {},
        },
    },
    parana: {
        images: {
            CaseAna_consultorio: 'https://cdn.eludico.io/conecta/CaseAna_consultorio.png',
            CaseAna_encerramento: 'https://cdn.eludico.io/conecta/CaseAna_encerramento.png',
            CaseAna_maquinadecafe: 'https://cdn.eludico.io/conecta/CaseAna_maquinadecafe.png',
            CaseAna_restaurante: 'https://cdn.eludico.io/conecta/CaseAna_restaurante.png',
            CaseAna_ruascuritiba: 'https://cdn.eludico.io/conecta/CaseAna_ruascuritiba.png',
            CaseAna_terere: 'https://cdn.eludico.io/conecta/CaseAna_terere.png',
            CaseAna_consultorio2: 'https://cdn.eludico.io/conecta/CaseAna_consultorio_2.png',
            CaseAna_restaurante_: 'https://cdn.eludico.io/conecta/CaseAna_restaurante_.png',
            CaseAna_ruascuritiba2: 'https://cdn.eludico.io/conecta/CaseAna_ruascuritiba_2.png',

            Ana_apontando: 'https://cdn.eludico.io/conecta/Ana_apontando.png',
            Ana_casual_neutra: 'https://cdn.eludico.io/conecta/Ana_casual_neutra.png',
            Ana_casual_observando: 'https://cdn.eludico.io/conecta/Ana_casual_observando.png',
            Ana_casual_pensativa: 'https://cdn.eludico.io/conecta/Ana_casual_pensativa.png',
            Ana_cumprimentando: 'https://cdn.eludico.io/conecta/Ana_cumprimentando.png',
            Ana_neutra: 'https://cdn.eludico.io/conecta/Ana_neutra.png',
            Ana_pensativa: 'https://cdn.eludico.io/conecta/Ana_pensativa.png',
            Ana_sorrindo: 'https://cdn.eludico.io/conecta/Ana_sorrindo.png',
        },
        audios: {
            bg: {
                bgs_curitiba2: 'https://cdn.eludico.io/conecta/bgs_curitiba2.ogg',
            },
            jng: {
                amb_ruascuritiba: 'https://cdn.eludico.io/conecta/amb_ruascuritiba.ogg',
                amb_restaurante: 'https://cdn.eludico.io/conecta/amb_restaurante.ogg',

                sfx_cafe: 'https://cdn.eludico.io/conecta/sfx_cafe.ogg',
                sfx_porta: 'https://cdn.eludico.io/conecta/sfx_porta.ogg',
            },
            sfx: {},
            voz: {},
        },
    },
};

class AssetsSystemCore {
    constructor() {
        this.global_audio_control = Howler;

        // this.global_audio_control.volume(window.localStorage.volume == undefined ? 1 : window.localStorage.volume);

        if (window.localStorage.isMuted != undefined) {
            this.global_audio_control.mute(window.localStorage.isMuted === 'true');
        }

        this.status = {
            download: { error: false, type: 'Image', porcent: 0, info: '0/0' },
            levels: {
                essential: { loadded: false },
                sp: { loadded: false },
                ceara: { loadded: false },
                parana: { loadded: false },
            },
        };

        this.audios = {};
        this.images = {};

        this.backgroundPlaying = '';
    }
    updateDownload(type, porcent, info) {
        this.status.download.porcent = porcent;
        this.status.download.type = type;
        this.status.download.info = info;

        window.dispatchEvent(new CustomEvent('AssetsSystemDownload', { detail: this.status.download }));
    }
    loading(level = 'essential') {
        const factory = async (resolve, reject) => {
            console.log('Iniciando download da fase:', level);
            const initLoadingMetric = performance.now()

            var assetsDownload = assets[level];

            console.log('[assets] Carregando Imagens');
            var image = await this.loadingImage(assetsDownload);

            if (image.error) {
                this.status.download.error = true;
                return reject({ type: 'Imagem' });
            }

            console.log('[assets] Carregando sons');
            var audio = await this.loadingAudio(assetsDownload);

            if (audio.error) {
                this.status.download.error = true;
                return reject({ type: 'Audio' });
            }

            this.status.levels[level].loadded = true;

            const endLoadingMetric = performance.now()

            posthog.capture('assets_download', {
                level,
                time: endLoadingMetric - initLoadingMetric
            })

            resolve();
            console.log(audio);
        };

        return new Promise((resolve2, reject2) => {
            if (this.status.levels[level] === undefined) {
                console.error('Fase não encontrada:', level);
                return reject2();
            }

            if (this.status.levels[level].loadded) {
                resolve2();
            } else {
                factory(resolve2, reject2);
            }
        });
    }
    loadingImage(assetsDownload) {
        return new Promise((resolve, reject) => {
            this.status.download.type = 'Imagem';
            var srcsLoading = [];

            var srcsImages = assetsDownload.images;

            var totalSrcs = 0;
            var count = 0;
            // eslint-disable-next-line no-unused-vars
            for (const key1 in srcsImages) {
                totalSrcs++;
            }

            for (var srcImage in srcsImages) {
                this.images[srcImage] = new Image();

                srcsLoading.push(
                    new Promise((resolve2, reject2) => {
                        this.images[srcImage].onload = resolve2;
                        this.images[srcImage].onerror = reject2;
                    }).then((val) => {
                        count++;
                        this.updateDownload('Imagem', parseInt((count * 100) / totalSrcs), `${count}/${totalSrcs}`);
                        return val;
                    }),
                );
                this.images[srcImage].src = srcsImages[srcImage];
            }

            console.log('Promessas de imagem', srcsLoading);
            Promise.all(srcsLoading)
                .then((ex) => {
                    this.status.download.porcent = 0;
                    resolve({ error: false, srcsLoading, ex });
                })
                .catch((ev) => {
                    // this.status.download.error = true;
                    // this.status.download.porcent = 0;
                    // reject({ error: true, srcsLoading, ev });
                })
                .finally(() => {
                    this.status.download.porcent = 0;
                    resolve({ error: false, srcsLoading });
                });
        });
    }
    loadingAudio(assetsDownload) {
        return new Promise((resolve, reject) => {
            this.status.download.type = 'Audio';

            var srcsLoading = [];

            var srcsAudios = [];

            var totalSrcs = 0;
            var count = 0;

            for (const type_src in assetsDownload.audios) {
                const audios = assetsDownload.audios[type_src];

                for (const audioName in audios) {
                    if (this.audios[audioName]) {
                        console.error('[loadingAudio] Audio ja existe, ignorando para download', audioName);
                    } else {
                        srcsAudios.push({
                            src: audios[audioName],
                            name: audioName,
                            type: type_src,
                        });
                    }
                }
            }

            totalSrcs = srcsAudios.length;

            console.log(srcsAudios);

            for (var index in srcsAudios) {
                const name = srcsAudios[index].name;
                const src = srcsAudios[index].src;
                const type = srcsAudios[index].type;

                this.audios[name] = new Howl({
                    src: [src],
                    id: name,
                    loop: type === 'bg',
                    volume: type === 'bg' ? 0.01 : type === 'voz' ? 0.7 : type === 'sfx' ? 0.7 : 1,
                });

                srcsLoading.push(
                    new Promise((resolve2, reject2) => {
                        this.audios[name].on('load', resolve2);
                        this.audios[name].on('loaderror', reject2);
                    }).then((val) => {
                        count++;
                        this.updateDownload('Audio', parseInt((count * 100) / totalSrcs), `${count}/${totalSrcs}`);
                        return val;
                    }),
                );
            }

            console.log('Promessas de audio', srcsLoading);

            setTimeout(() => {
                this.status.download.porcent = 0;
                resolve({ error: false, srcsLoading });
            }, 4000);

            Promise.all(srcsLoading)
                .then((ex) => {
                    this.status.download.porcent = 0;
                    resolve({ error: false, srcsLoading, ex });
                })
                .catch((ev) => {
                    this.status.download.error = true;
                    this.status.download.porcent = 0;
                    reject({ error: true, ev });
                });
        });
    }
    getImage(imageName) {
        if (this.images[imageName] === undefined) return console.log('Imagem nao encontrada', imageName);

        return this.images[imageName].src;
    }
    play(audioName, loop, volume = 1) {
        if (this.audios[audioName] == undefined) return console.log(`Audio nao encontrado: ${audioName}`);

        this.audios[audioName].play();
        this.audios[audioName].fade(0, volume, 1000);
        if (loop) this.audios[audioName].loop(loop);
    }
    play_background(audioName) {
        if (this.backgroundPlaying == '') {
            this.play(audioName, false, 0.1);
            this.backgroundPlaying = audioName;
        } else {
            if (this.backgroundPlaying != audioName) {
                this.stop(this.backgroundPlaying, 0.1);
                if (this.audios[audioName].playing()) return console.log('BG ja esta sendo reproduzido', audioName);
                this.play(audioName, false, 0.1);
                this.backgroundPlaying = audioName;
            }
        }
    }
    play_customer_speech(typeClient, mood) {
        const audioName = {
            very_negative: 'VOZ_Muito_Negativo_P',
            negative: 'VOZ_Negativo_P',
            start: 'VOZ_Inicio_P',
            very_positive: 'VOZ_Muito_Positivo_P',
            positive: 'VOZ_Positivo_P',
        };

        var name = `${audioName[mood]}${typeClient}`;

        this.audios[name].play();
    }
    stop(audioName, volume = 1) {
        if (this.audios[audioName] == undefined) return console.log('Audio nao encontrado', audioName);

        this.audios[audioName].fade(volume, 0, 1000);
        setTimeout(() => {
            this.audios[audioName].stop();
        }, 1000);
    }
    mute() {
        var isMuted;

        if (window.localStorage.isMuted) isMuted = window.localStorage.isMuted === 'true';
        else isMuted = this.global_audio_control._muted;

        var muted = !isMuted;

        window.localStorage.setItem('isMuted', muted);

        console.log(muted);
        this.global_audio_control.mute(muted);
        return muted;
    }
    volume(volume) {
        window.localStorage.setItem('volume', volume);
        this.global_audio_control.volume(volume);
    }
}

const assetsSystem = new AssetsSystemCore();

export default assetsSystem;
