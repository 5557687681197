<template>
    <div v-for="(storyBoard, index) in storyBoards" :key="index">
        <v-tour :name="index" :steps="storyBoard" :callbacks="callbacks"></v-tour>
    </div>
</template>

<script>
    import { reactive, ref } from 'vue';
    import tutorial from '@/controlers/tutorialControl';
    export default {
        name: 'TutorialV',
        setup() {
            const storyBoards = ref({});
            storyBoards.value = tutorial.storyBoards();

            const callbacks = reactive({
                onFinish: () => {
                    tutorial.completed();
                },
            });

            return { storyBoards, callbacks };
        },
    };
</script>

<style></style>
