import axios from 'axios';
import ToastEventBus from 'primevue/toasteventbus';
import auth from './authentication';
import posthog from 'posthog-js';

function useToast() {
    const ToastService = {
        add: (message) => {
            ToastEventBus.emit('add', message);
        },
        removeGroup: (group) => {
            ToastEventBus.emit('remove-group', group);
        },
        removeAllGroups: () => {
            ToastEventBus.emit('remove-all-groups');
        },
    };
    return ToastService;
}

const toast = useToast();

import axioMockup from './apiMockup'; //https://github.com/ctimmerm/axios-mock-adapter

var useMockup = process.env.VUE_APP_USE_API_MOCKUP;

var http;
if (useMockup == 'true') {
    http = axioMockup.create({
        baseURL: process.env.VUE_APP_API,
    });
} else {
    http = axios.create({
        baseURL: process.env.VUE_APP_API,
        // baseURL: 'https://0e45-187-3-9-209.ngrok.io/',
    });
}

const ipify = axios.create({
    baseURL: 'https://api.ipify.org',
});

const cep = axios.create({
    baseURL: 'https://brasilapi.com.br/api/cep/v1/',
});

const municipios = axios.create({
    baseURL: 'https://brasilapi.com.br/api/ibge/municipios/v1/',
});

const pagarmeApi = axios.create({
    baseURL: 'https://api.pagar.me/1/',
});

http.interceptors.request.use((config) => {
    if (!config.headers.token) {
        if (config.url != '/login' && config.url != '/forgotPassword') {
            const token = auth.getToken();
            config.headers.authorization = `Bearer ${token}`;
        }
    }
    return config;
});

http.interceptors.response.use(
    (response) => {
        // console.log(response);
        let responseLog = {
            method: response.config.method,
            from: response.config.url,
            reqHeaders: response.config.headers,
            reqData: response.config.data,
            r: response.data,
        };

        posthog.capture(`conecta-request-frontend-${responseLog.from}`, responseLog);

        console.log(responseLog);

        if (responseLog.from.includes("/read-message")) {

            return response
        }

        if (response.data.message) {
            toast.add({
                severity: 'success',
                // summary: 'Erro Grave',
                detail: response.data.message,
                life: 3000,
            });
        }

        if (response.data.error) {
            // core.$vs.notification({
            //     title: response.data.error.title,
            //     text: response.data.error.message,
            //     color: 'danger',
            //     position: 'top-right',
            // });
        }

        return response;
    },
    (error) => {
        console.error({ error });
        let errorLog = {
            method: error.config.method,
            from: error.config.url,
            reqHeaders: error.config.headers,
            reqData: error.config.data,
            r: error.response.data,
            status: error.response.status,
            type: 'error'
        };

        posthog.capture(`conecta-request-error-frontend-${errorLog.from}`, errorLog);

        console.error(errorLog);

        if (errorLog.from == '/answer') {
            toast.add({
                severity: 'error',
                summary: 'Houve um erro para salvar sua resposta',
                detail: 'Desculpe, não foi possível salvar sua resposta. Sua questão foi reiniciada. Por favor, tente novamente',
                life: 6000,
            });
            return Promise.reject(error);
        }

        if (errorLog.from.includes("/read-message")) {
            toast.add({
                severity: 'error',
                summary: 'Erro',
                detail: 'Desculpe, não foi possível ler a mensagem. Por favor, tente ler a mensagem novamente',
                life: 6000,
            });
            return Promise.reject(error);
        }



        if (typeof error.response.data.message === 'string') {
            toast.add({
                severity: 'error',
                summary: "erro",
                detail: error.response.data.message,
                life: 3000,
            });

            return Promise.reject(error);
        }

        if (typeof error.response.data.messages === 'string') {
            toast.add({
                severity: 'error',
                summary: "Erro",
                detail: error.response.data.messages,
                life: 3000,
            });

            return Promise.reject(error);
        }

        toast.add({
            severity: 'error',
            summary: 'Problema de comunicação',
            detail: 'Desculpe-nos, parece que estamos enfrentando um problema de comunicação com o servidor. Não se preocupe, você ainda pode continuar jogando normalmente enquanto trabalhamos para resolver o problema, ou então tente novamente mais tarde.',
            life: 6000,
        });

        // if (typeof error.response.data.error === 'string') {
        //     toast.add({
        //         severity: 'error',
        //         summary: 'Erro Grave',
        //         detail: error.response.data.error,
        //         life: 6000,
        //     });
        // } else {
        //     const errors = error.response.data.errors;
        //     if (typeof errors === 'string') {
        //         toast.add({
        //             severity: 'error',
        //             summary: error.response.data.error.title,
        //             detail: error.response.data.error.message,
        //             life: 6000,
        //         });
        //     } else {
        //         for (let index = 0; index < errors.length; index++) {
        //             const element = errors[index];

        //             toast.add({
        //                 severity: 'error',
        //                 summary: element.field,
        //                 detail: element.message,
        //                 life: 6000,
        //             });
        //         }
        //     }
        // }

        return Promise.reject(error);
    },
);

const api = {
    axios,
    instance: http,
    get(endpoint, body) {
        return http.get(`${endpoint}`, { params: body });
    },
    post(endpoint, body, config) {
        return http.post(endpoint, body, config);
    },
    put(endpoint, body, config) {
        console.log(body);
        return http.put(endpoint, body, config);
    },
    delete(endpoint, body) {
        return http.delete(endpoint, body);
    },
    busca(endpoint) {
        return http.get(endpoint);
    },
    getIp(endpoint) {
        return ipify.get(`${endpoint}`);
    },
    getCep(endpoint) {
        return cep.get(`${endpoint}`);
    },
    getMunicipios(endpoint) {
        return municipios.get(`${endpoint}`);
    },
};

const pagarme = {
    get(endpoint, body) {
        return pagarmeApi.get(`${endpoint}`, { params: { ...body, api_key: process.env.VUE_APP_PAGARME_KEY } });
    },
    createCard(body) {
        return new Promise((resolve, reject) => {
            const data = { ...body, api_key: process.env.VUE_APP_PAGARME_KEY };
            console.log(data);
            pagarmeApi
                .post(`/cards`, { params: data })
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    post(endpoint, body, config) {
        return pagarmeApi.post(endpoint, body, config);
    },
};

export { api, pagarme };
