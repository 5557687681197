
export function typeOS() {
  let result = 'naodefinido'
 
  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    
    result = 'iphone'
  }

  if (/Android/i.test(navigator.userAgent)) {
    result = 'android'
  }

  return result
}