/* eslint-disable no-unused-vars */
import { api } from './services';

class core {
    constructor(opt = { storage: 'localStorage', tokenValid: 21600000 }) {
        this.token = undefined;
        this.user = undefined;
        this.accessLevel = undefined;
        this.tokenValid = opt.tokenValid || 21600000;

        this.logged = false;

        this.storage = {};
        // if (opt.storage == 'localStorage') {
        //     this.storage = window.localStorage;
        // } else {
        //     this.storage = window.sessionStorage;
        // }
    }
    load() {
        if (this.storage.token !== undefined) {
            var ds = this.storage;

            this.token = ds.token;
            this.user = ds.data;
            this.accessLevel = ds.userType;
            this.timingToken = ds.date;

            this.logged = true;
        } else {
            console.log('usuário não está logado');
            this.logged = false;
        }
    }
    isAuthenticated() {
        if (this.logged) {
            var period = this.timingToken - Date.now(); //verifica quanto tempo o tokem esta guardado
            if (-period < this.tokenValid) {
                // se for menor que a validade do token é valido
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    async login(form) {
        try {
            // const res = await api.post('/login', form);

            var token = form.token;
            var userType = form.data.user_type;
            var data = form.data;

            return new Promise((resolve, reject) => {
                if (data == undefined)
                    reject({ title: 'Erro crítico.', text: 'Formato de dados inválido', color: 'danger' });
                if (token == undefined)
                    reject({ title: 'Erro crítico.', text: 'Formato de dados inválido', color: 'danger' });

                var dataToStringFy = {
                    token,
                    data,
                    userType,
                    date: Date.now(),
                };
                try {
                    this.storage = dataToStringFy;
                    this.load();
                    resolve(dataToStringFy);
                } catch (error) {
                    reject({
                        title: 'Erro crítico.',
                        text: `Erro no sistema de autenticação, recarregue a página ou entre em contato.`,
                        color: 'danger',
                    });
                    console.error(error);
                }
            });
        } catch (error) {
            console.log(error);
            return error;
        }
    }
    logout() {
        return new Promise((resolve, reject) => {
            if (this.logged) {
                // services.post('/logout').finally(() => {
                try {
                    this.storage = {};
                    this.logged = false;
                    this.token = undefined;
                    this.user = undefined;
                    this.accessLevel = undefined;

                    document.dispatchEvent(new Event('logout'));
                    resolve();
                } catch (error) {
                    console.error({
                        title: 'Erro crítico.',
                        text: `Erro no sistema de logout, recarregue a página ou entre em contato.`,
                        color: 'danger',
                        error,
                    });
                    reject({
                        title: 'Erro crítico.',
                        text: `Erro no sistema de logout, recarregue a página ou entre em contato.`,
                        color: 'danger',
                        error,
                    });
                }
                // });
            } else {
                // console.warn('[auth] Usuário não esta logado');
                // reject('[auth] Usuário não esta logado');
            }
        });
    }
    getData() {
        if (this.logged) {
            var ds = this.storage;
            return ds.data;
        } else {
            console.warn('[auth] Usuário não esta logado');
            return { text: 'Usuário não autenticado ' };
        }
    }
    getToken() {
        if (this.logged) {
            var ds = this.storage;
            return ds.token;
        } else {
            console.warn('[auth] Usuário não esta logado');
            return { text: 'Usuário não autenticado ' };
        }
    }
    getUserType() {
        if (this.logged) {
            var ds = this.storage;
            return ds.userType;
        } else {
            console.warn('[auth] Usuário não esta logado');
            return { text: 'Usuário não autenticado ' };
        }
    }
}

const authentication = new core({
    storage: 'localStorage',
    tokenValid: 21600000,
});

export default authentication;
